<template lang="pug">
div(style="min-height: 70vh")
  v-card.pa-5(min-height="420")
    div.d-flex.justify-center
      v-card.ml-auto.pr-3(
        v-if="$vuetify.breakpoint.xs"
        tile,
        max-width="200"
        flat
      )
        img.rounded-circle.ml-auto.pa-3.mr-n5(
          :src="require(`@/assets/institutes/${institute.image}`)",
          height="100px"
        )
      v-card.ml-auto.pr-3(
        style="border-right: 2px solid #010073",
        tile,
        flat
        max-width="200"
        v-else
      )
        img.rounded-circle.ml-auto(
          :src="require(`@/assets/institutes/${institute.image}`)",
          height="100px"
        )
      v-card.mr-auto.pl-3(max-width="800", color="transparent", flat).d-flex.align-center
        .mr-1.font-weight-light.display-1.text-uppercase.text-start(v-if="$vuetify.breakpoint.mdAndDown") {{ institute.acronym }}
        .mr-1.font-weight-light.display-1.text-uppercase.text-start(v-else) {{ institute.name }}
    paginate(
    :limit="limit",
    collection="books",
    order="title",
    field="institution",
    equality="array-contains",
    :value="value"
    )
      template(v-slot:content="{ contents }")
        v-row.text-center.mx-auto.mt-11
          v-col(v-for="book in contents", :key="book.id", md="3", lg="2", sm="4" cols="6 ")
            tooltipBtn
              template(v-slot:tooltip-content)
                div {{ book.title }}
                div.caption.font-weight-light
                  span(v-if="book.author.length == 1") by {{ book.author[0] }}
                  span(v-else) by {{ book.author[0] }} <span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }}
                div.caption.font-weight-light {{book.publisher}} • {{book.year}}
              template(v-slot:content)
                v-card.mx-auto.justify-content-center.align-center(height="235px", width="150px", elevation="0", tile @click="link(book.id)")
                  bookCover(:url="book.imageUrl" imgHeight="160" imgWidth="110").mx-auto
                  v-list-item-group.mx-auto
                    v-list-item-content
                      v-list-item-title.body-1 
                        strong {{ book.title }}
                      v-list-item-title.mt-n1.body-2.text--secondary by &nbsp
                        span(v-if="book.author.length == 1") {{ book.author[0] }}
                        span(v-else) {{ book.author[0] }} <span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }}
</template>

<script>
import syllabi from "@/syllabi.js";
import bookCover from "../components/BookCover.vue";
import paginate from "../components/Paginate.vue";
import tooltipBtn from "../components/TooltipBtn.vue";
export default {
  components: { bookCover, paginate, tooltipBtn },
  data() {
    return {
      value: this.$route.params.slug,
      limit: 12,
    };
  },

  computed: {
    institute() {
      return syllabi.institutes.find(
        (institute) => institute.name === this.value
      );
    },
  },

  methods: {
    link: function (id) {
      this.$router.push(`../book-item/${id}`);
    },
  },
};
</script>

<style scoped></style>
